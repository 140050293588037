import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import React, { useContext } from "react"

import Layout from "../Layout"
import Container from "../Layout/Container"
import Section from "../Elements/Section"
import Message from "../Elements/Message"
import PaymentMethod from "./PaymentMethod"
import ActionButtons from "../Elements/ActionButtons"

import { EpharmacyContext } from "./EpharmacyContext/EpharmacyContext"
import {
  accumulatePrice,
  formatPrice,
} from "../Epharmacy/services/computations"
import { paymentDetailsValidationSchema } from "./utils/paymentDetailsValidationSchema"
import { DELIVERY_FEE } from "./services/constants"

const PaymentDetails = ({ pageContext }) => {
  const { module } = pageContext
  const { epharmacyState, epharmacyDispatch } = useContext(EpharmacyContext)
  const cartTotal = formatPrice({
    priceString: (accumulatePrice(epharmacyState?.medicines) + DELIVERY_FEE).toFixed(2),
  })
  return (
    <Layout {...module}>
      <Container isCentered desktop={6} fullhd={6}>
        <Formik
          initialValues={epharmacyState}
          onSubmit={async (values, { setErrors }) => {
            if (
              values?.paymentOption === "Cash on Delivery" &&
              parseFloat(cartTotal.replace(",", "")) > values?.changeFor
            ) {
              setErrors({
                changeFor:
                  "Please input an amount sufficient to cover your order total.",
              })
            } else {
              epharmacyDispatch({
                type: "SAVE_EPHARMACY",
                payload: values,
              })

              navigate("/epharmacy/summary")
            }
          }}
          validationSchema={paymentDetailsValidationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Message className="mx-1">
                You may choose cash-on-delivery (COD) for faster processing. If
                you select COD, please prepare your payment in advance. Your
                order will be delivered in 1-2 working days.
              </Message>
              <Section className="mt-3 mb-3">
                <PaymentMethod
                  values={values}
                  setFieldValue={setFieldValue}
                  cartTotal={cartTotal}
                />
              </Section>

              <ActionButtons
                submit={{
                  label: "Review Order",
                  disabled:
                    !values.paymentOption ||
                    (values?.paymentOption === "Cash on Delivery" &&
                      !values?.changeFor),
                }}
                back={{ label: "Back", link: "/epharmacy/checkout" }}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default PaymentDetails
