import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"

export const paymentDetailsValidationSchema = Yup.object().shape({
  changeFor: Yup.number().when("paymentOption", {
    is: (paymentOption) => paymentOption === "Cash on Delivery",
    then: Yup.number().min(0, 'Please input a valid amount').required(REQUIRED_MESSAGE),
  }),
  preferredBank: Yup.string().when("paymentOption", {
    is: (paymentOption) => paymentOption === "Bank Deposit",
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
})
